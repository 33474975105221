body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: 100vw;
}

.input {
  background: #eaeef3;
  padding: 8px;
  border-radius: 12px 0px 0px 12px;
  flex-grow: 1;
  min-width: 250px;
  font-size: 14px !important;
}

.buttonInput {
  background: #d7dce1 !important;
  border-radius: 0px 12px 12px 0px !important;
  padding: 8px 16px !important;
  color: #607d8b !important;
}

.paper {
  border-radius: 12px !important;
  padding: 16px;
}

.content {
  flex-grow: 1;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.header {
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-top: 16px;
}

.footer {
  max-width: 600px;
  text-align: center;
  margin: auto;
  padding-bottom: 16px;
}

.button {
  border-radius: 12px !important;
}

.flex {
  display: flex;
}
.end {
  justify-content: flex-end;
}
